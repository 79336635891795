export const LIST_CATASTROPHES = 'LIST_CATASTROPHES'
export const LIST_CATASTROPHE_SUCCESS = 'LIST_CATASTROPHE_SUCCESS';
export const LIST_CATASTROPHE_FAIL = 'LIST_CATASTROPHE_FAIL';

export const LIST_DENRES = 'LIST_DENRES';
export const LIST_DENRE_SUCCESS = 'LIST_DENRE_SUCESS';
export const LIST_DENRE_FAIL = 'LIST_DENRE_FAIL';

export const LIST_MATERIELS = 'LIST_MATERIELS';
export const LIST_MATERIEL_SUCCESS = 'LIST_MATERIEL_SUCCESS';
export const LIST_MATERIEL_FAIL = 'LIST_MATERIEL_FAIL';

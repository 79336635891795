export const GET_PROFILE_ALIMENTAIRES = "GET_PROFILE_ALIMENTAIRES"
export const GET_PROFILE_ALIMENTAIRE_SUCCESS = "GET_PROFILE_ALIMENTAIRE_SUCCESS"
export const GET_PROFILE_ALIMENTAIRE_ERROR = "GET_PROFILE_ALIMENTAIRE_ERROR"

export const POST_PROFILE_ALIMENTAIRES = "POST_PROFILE_ALIMENTAIRES"
export const POST_PROFILE_ALIMENTAIRE_SUCCESS = "POST_PROFILE_ALIMENTAIRE_SUCCESS"
export const POST_PROFILE_ALIMENTAIRE_ERROR = "POST_PROFILE_ALIMENTAIRE_ERROR"

export const SHOW_PROFILE_ALIMENTAIRE = "SHOW_PROFILE_ALIMENTAIRE"
export const SHOW_PROFILE_ALIMENTAIRE_SUCCESS = "SHOW_PROFILE_ALIMENTAIRE_SUCCESS"
export const SHOW_PROFILE_ALIMENTAIRE_ERROR = "SHOW_PROFILE_ALIMENTAIRE_ERROR"

export const PUT_PROFILE_ALIMENTAIRES = "PUT_PROFILE_ALIMENTAIRES"
export const PUT_PROFILE_ALIMENTAIRE_SUCCESS = "PUT_PROFILE_ALIMENTAIRE_SUCCESS"
export const PUT_PROFILE_ALIMENTAIRE_ERROR = "PUT_PROFILE_ALIMENTAIRE_ERROR"

export const DEL_PROFILE_ALIMENTAIRES = "DEL_PROFILE_ALIMENTAIRES"
export const DEL_PROFILE_ALIMENTAIRE_SUCCESS = "DEL_PROFILE_ALIMENTAIRE_SUCCESS"
export const DEL_PROFILE_ALIMENTAIRE_ERROR = "DEL_PROFILE_ALIMENTAIRE_ERROR"
export const GET_PRIX_PRODUIT = "GET_PRIX_PRODUIT"
export const GET_PRIX_PRODUIT_SUCCESS = "GET_PRIX_PRODUIT_SUCCESS"
export const GET_PRIX_PRODUIT_ERROR = "GET_PRIX_PRODUIT_ERROR"

export const POST_PRIX_PRODUIT = "POST_PRIX_PRODUIT"
export const POST_PRIX_PRODUIT_SUCCESS = "POST_PRIX_PRODUIT_SUCCESS"
export const POST_PRIX_PRODUIT_ERROR = "POST_PRIX_PRODUIT_ERROR"

export const SHOW_PRIX_PRODUIT = "SHOW_PRIX_PRODUIT"
export const SHOW_PRIX_PRODUIT_SUCCESS = "SHOW_PRIX_PRODUIT_SUCCESS"
export const SHOW_PRIX_PRODUIT_ERROR = "SHOW_PRIX_PRODUIT_ERROR"

export const PUT_PRIX_PRODUIT = "PUT_PRIX_PRODUIT"
export const PUT_PRIX_PRODUIT_SUCCESS = "PUT_PRIX_PRODUIT_SUCCESS"
export const PUT_PRIX_PRODUIT_ERROR = "PUT_PRIX_PRODUIT_ERROR"

export const DEL_PRIX_PRODUIT = "DEL_PRIX_PRODUIT"
export const DEL_PRIX_PRODUIT_SUCCESS = "DEL_PRIX_PRODUIT_SUCCESS"
export const DEL_PRIX_PRODUIT_ERROR = "DEL_PRIX_PRODUIT_ERROR"
/* PERSONNES REFUGIES AND MIGRANT*/
export const GET_PERSONNES = "GET_PERSONNES"
export const GET_PERSONNES_SUCCESS = "GET_PERSONNES_SUCCESS"
export const GET_PERSONNES_FAIL = "GET_PERSONNES_FAIL"

/* PERSONNES REFUGIES*/
export const GET_REFUGIES = "GET_REFUGIES"
export const GET_REFUGIES_SUCCESS = "GET_REFUGIES_SUCCESS"
export const GET_REFUGIES_FAIL = "GET_REFUGIES_FAIL"

/* PERSONNES DEPLACE INTERNE*/
export const GET_DEPLACEINTERNE = "GET_DEPLACEINTERNE"
export const GET_DEPLACEINTERNE_SUCCESS = "GET_DEPLACEINTERNE_SUCCESS"
export const GET_DEPLACEINTERNE_FAIL = "GET_DEPLACEINTERNE_FAIL"

/* PERSONNES MIGRANT*/
export const GET_MIGRANTS = "GET_MIGRANTS"
export const GET_MIGRANTS_SUCCESS = "GET_MIGRANTS_SUCCESS"
export const GET_MIGRANTS_FAIL = "GET_MIGRANTS_FAIL"

/* PERSONNE DETAIL */
export const GET_PERSONNE_SUCCESS = "GET_PERSONNE_SUCCESS"
export const GET_PERSONNE_FAIL = "GET_PERSONNE_FAIL"
export const GET_PERSONNE = "GET_PERSONNE"
/**
 * add PERSONNE
 */
export const ADD_NEW_PERSONNE = "ADD_NEW_PERSONNE"
export const ADD_PERSONNE_SUCCESS = "ADD_PERSONNE_SUCCESS"
export const ADD_PERSONNE_FAIL = "ADD_PERSONNE_FAIL"

/**
* Edit PERSONNE
*/
export const UPDATE_PERSONNE = "UPDATE_PERSONNE"
export const UPDATE_PERSONNE_SUCCESS = "UPDATE_PERSONNE_SUCCESS"
export const UPDATE_PERSONNE_FAIL = "UPDATE_PERSONNE_FAIL"

/**
 * Delete PERSONNE
 */
export const DELETE_PERSONNE = "DELETE_PERSONNE"
export const DELETE_PERSONNE_SUCCESS = "DELETE_PERSONNE_SUCCESS"
export const DELETE_PERSONNE_FAIL = "DELETE_PERSONNE_FAIL"



// PREFECTURES
export const GET_PREFECTURES = "GET_PREFECTURES"
export const GET_PREFECTURES_SUCCESS = "GET_PREFECTURES_SUCCESS"
export const GET_PREFECTURES_FAIL = "GET_PREFECTURES_FAIL"


/* PREFECTURES DETAIL */
export const GET_PREFECTURE_SUCCESS = "GET_PREFECTURE_SUCCESS"
export const GET_PREFECTURE_FAIL = "GET_PREFECTURE_FAIL"
export const GET_PREFECTURE = "GET_PREFECTURE"
/**
 * ADD PREFECTURE
 */
export const ADD_NEW_PREFECTURE = "ADD_NEW_PREFECTURE"
export const ADD_PREFECTURE_SUCCESS = "ADD_PREFECTURE_SUCCESS"
export const ADD_PREFECTURE_FAIL = "ADD_PREFECTURE_FAIL"

/**
* Edit PREFECTURES
*/
export const UPDATE_PREFECTURE = "UPDATE_PREFECTURE"
export const UPDATE_PREFECTURE_SUCCESS = "UPDATE_PREFECTURE_SUCCESS"
export const UPDATE_PREFECTURE_FAIL = "UPDATE_PREFECTURE_FAIL"

/**
 * Delete PREFECTURES
 */
export const DELETE_PREFECTURE = "DELETE_PREFECTURE"
export const DELETE_PREFECTURE_SUCCESS = "DELETE_PREFECTURE_SUCCESS"
export const DELETE_PREFECTURE_FAIL = "DELETE_PREFECTURE_FAIL"

/**
 * GET PREFECTURE BY REGIONS
*/
export const GET_PREFECTURE_BY_REGION = "GET_PREFECTURE_BY_REGION"
export const GET_PREFECTURE_BY_REGION_SUCCESS = "GET_PREFECTURE_BY_REGION_SUCCESS"
export const GET_PREFECTURE_BYD_REGION_FAIL = "GET_PREFECTURE_BYD_REGION_FAIL"



// COMMUNES
export const GET_COMMUNES = "GET_COMMUNES"
export const GET_COMMUNES_SUCCESS = "GET_COMMUNES_SUCCESS"
export const GET_COMMUNES_FAIL = "GET_COMMUNES_FAIL"


/* COMMUNES DETAIL */
export const GET_COMMUNE_SUCCESS = "GET_COMMUNE_SUCCESS"
export const GET_COMMUNE_FAIL = "GET_COMMUNE_FAIL"
export const GET_COMMUNE = "GET_COMMUNE"
/**
 * ADD COMMUNE
 */
export const ADD_NEW_COMMUNE = "ADD_NEW_COMMUNE"
export const ADD_COMMUNE_SUCCESS = "ADD_COMMUNE_SUCCESS"
export const ADD_COMMUNE_FAIL = "ADD_COMMUNE_FAIL"

/**
* Edit COMMUNES
*/
export const UPDATE_COMMUNE = "UPDATE_COMMUNE"
export const UPDATE_COMMUNE_SUCCESS = "UPDATE_COMMUNE_SUCCESS"
export const UPDATE_COMMUNE_FAIL = "UPDATE_COMMUNE_FAIL"

/**
 * Delete COMMUNES
 */
export const DELETE_COMMUNE = "DELETE_COMMUNE"
export const DELETE_COMMUNE_SUCCESS = "DELETE_COMMUNE_SUCCESS"
export const DELETE_COMMUNE_FAIL = "DELETE_COMMUNE_FAIL"

/**
 * GET COMMUNES PREFECTURES
 */
export const GET_COMMUNE_PREFECTURE = "GET_COMMUNE_PREFECTURE"
export const GET_COMMUNE_PREFECTURE_SUCCESS = "GET_COMMUNE_PREFECTURE_SUCCESS"
export const GET_COMMUNE_PREFECTURE_FAIL = "GET_COMMUNE_PREFECTURE_FAIL"


/* DEPLACANTS */
export const GET_DEPLACANTS = "GET_DEPLACANTS"
export const GET_DEPLACANTS_SUCCESS = "GET_DEPLACANTS_SUCCESS"
export const GET_DEPLACANTS_FAIL = "GET_DEPLACANTS_FAIL"


/* DEPLACANT DETAIL */
export const GET_DEPLACANT_SUCCESS = "GET_DEPLACANT_SUCCESS"
export const GET_DEPLACANT_FAIL = "GET_DEPLACANT_FAIL"
export const GET_DEPLACANT = "GET_DEPLACANT"
/**
 * add DEPLACANT
 */
export const ADD_NEW_DEPLACANT = "ADD_NEW_DEPLACANT"
export const ADD_DEPLACANT_SUCCESS = "ADD_DEPLACANT_SUCCESS"
export const ADD_DEPLACANT_FAIL = "ADD_DEPLACANT_FAIL"

/**
* Edit DEPLACANT
*/
export const UPDATE_DEPLACANT = "UPDATE_DEPLACANT"
export const UPDATE_DEPLACANT_SUCCESS = "UPDATE_DEPLACANT_SUCCESS"
export const UPDATE_DEPLACANT_FAIL = "UPDATE_DEPLACANT_FAIL"

/**
 * Delete DEPLACANT
 */
export const DELETE_DEPLACANT = "DELETE_DEPLACANT"
export const DELETE_DEPLACANT_SUCCESS = "DELETE_DEPLACANT_SUCCESS"
export const DELETE_DEPLACANT_FAIL = "DELETE_DEPLACANT_FAIL"



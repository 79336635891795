export const GET_PRODUIT = "GET_PRODUIT"
export const GET_PRODUIT_SUCCESS = "GET_PRODUIT_SUCCESS"
export const GET_PRODUIT_ERROR = "GET_PRODUIT_ERROR"

export const POST_PRODUIT = "POST_PRODUIT"
export const POST_PRODUIT_SUCCESS = "POST_PRODUIT_SUCCESS"
export const POST_PRODUIT_ERROR = "POST_PRODUIT_ERROR"

export const SHOW_PRODUIT = "SHOW_PRODUIT"
export const SHOW_PRODUIT_SUCCESS = "SHOW_PRODUIT_SUCCESS"
export const SHOW_PRODUIT_ERROR = "SHOW_PRODUIT_ERROR"

export const PUT_PRODUIT = "PUT_PRODUIT"
export const PUT_PRODUIT_SUCCESS = "PUT_PRODUIT_SUCCESS"
export const PUT_PRODUIT_ERROR = "PUT_PRODUIT_ERROR"

export const DEL_PRODUIT = "DEL_PRODUIT"
export const DEL_PRODUIT_SUCCESS = "DEL_PRODUIT_SUCCESS"
export const DEL_PRODUIT_ERROR = "DEL_PRODUIT_ERROR"
/* UTILISATEURS */
export const GET_UTILISATEURS = "GET_UTILISATEURS"
export const GET_UTILISATEURS_SUCCESS = "GET_UTILISATEURS_SUCCESS"
export const GET_UTILISATEURS_REQUESTED = "GET_UTILISATEURS_REQUESTED"
export const GET_UTILISATEURS_FAIL = "GET_UTILISATEURS_FAIL"

/* UTILISATEUR DETAIL */
export const GET_UTILISATEUR_SUCCESS = "GET_UTILISATEUR_SUCCESS"
export const GET_UTILISATEUR_FAIL = "GET_UTILISATEUR_FAIL"
export const GET_UTILISATEUR = "GET_UTILISATEUR"
/**
 * add UTILISATEUR
 */
export const PROFILE_NEW_UTILISATEUR = "PROFILE_NEW_UTILISATEUR"
export const PROFILE_UTILISATEUR_SUCCESS = "PROFILE_UTILISATEUR_SUCCESS"
export const PROFILE_UTILISATEUR_FAIL = "PROFILE_UTILISATEUR_FAIL"
export const PASSWORD_NEW_UTILISATEUR = "PASSWORD_NEW_UTILISATEUR"
export const PASSWORD_UTILISATEUR_SUCCESS = "PASSWORD_UTILISATEUR_SUCCESS"
export const PASSWORD_UTILISATEUR_FAIL = "PASSWORD_UTILISATEUR_FAIL"

export const ADD_NEW_UTILISATEUR = "ADD_NEW_UTILISATEUR"
export const ADD_UTILISATEUR_SUCCESS = "ADD_UTILISATEUR_SUCCESS"
export const ADD_UTILISATEUR_FAIL = "ADD_UTILISATEUR_FAIL"

/**
* Edit UTILISATEUR
*/
export const UPDATE_UTILISATEUR = "UPDATE_UTILISATEUR"
export const UPDATE_UTILISATEUR_SUCCESS = "UPDATE_UTILISATEUR_SUCCESS"
export const UPDATE_UTILISATEUR_FAIL = "UPDATE_UTILISATEUR_FAIL"

/**
 * Delete UTILISATEUR
 */
export const DELETE_UTILISATEUR = "DELETE_UTILISATEUR"
export const DELETE_UTILISATEUR_SUCCESS = "DELETE_UTILISATEUR_SUCCESS"
export const DELETE_UTILISATEUR_FAIL = "DELETE_UTILISATEUR_FAIL"


